import logo from './card1-1.png';
import './App.css';

function App() {
  return (
    <div className="App">

  <img src={logo} alt={"photo"}/>
    </div>
  );
}

export default App;
